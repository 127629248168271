.Highlightable .Highlighted {
  transition: opacity 100ms ease-in !important;
}

.Highlightable .Unhighlighted {
  transition: opacity 100ms ease-in !important;
}

.Highlightable .Annotation {
  transition: opacity 100ms ease-in !important;
}

.Highlightable:not(:hover) .Highlighted {
  opacity: 1 !important;
}

.Highlightable:not(:hover) .Unhighlighted {
  opacity: 0.25 !important;
}

.Highlightable:not(:hover) .Annotation {
  opacity: 1 !important;
}

.Highlightable:hover .Annotation {
  opacity: 0 !important;
}
