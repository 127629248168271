.blog div,
.blog span,
.blog applet,
.blog object,
.blog iframe,
.blog h1,
.blog h2,
.blog h3,
.blog h4,
.blog h5,
.blog h6,
.blog p,
.blog blockquote,
.blog pre,
.blog a,
.blog abbr,
.blog acronym,
.blog address,
.blog big,
.blog cite,
.blog code,
.blog del,
.blog dfn,
.blog em,
.blog img,
.blog ins,
.blog kbd,
.blog q,
.blog s,
.blog samp,
.blog small,
.blog strike,
.blog strong,
.blog sub,
.blog sup,
.blog tt,
.blog var,
.blog dl,
.blog dt,
.blog dd,
.blog ol,
.blog ul,
.blog li,
.blog fieldset,
.blog form,
.blog label,
.blog legend,
.blog table,
.blog caption,
.blog tbody,
.blog tfoot,
.blog thead,
.blog tr,
.blog th,
.blog td,
.blog article,
.blog aside,
.blog canvas,
.blog details,
.blog embed,
.blog figure,
.blog figcaption,
.blog footer,
.blog header,
.blog hgroup,
.blog main,
.blog menu,
.blog nav,
.blog output,
.blog ruby,
.blog section,
.blog summary,
.blog time,
.blog mark,
.blog audio,
.blog video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
.blog article,
.blog aside,
.blog details,
.blog figcaption,
.blog figure,
.blog footer,
.blog header,
.blog hgroup,
.blog main,
.blog menu,
.blog nav,
.blog section {
  display: block;
}
.blog {
  line-height: 1;
}
.blog.parent {
  margin-top: 2.4rem;
}
@media (max-width: 720px) {
  .blog.parent {
    margin-top: 0.7rem;
  }
}
.blog ol,
.blog ul {
  list-style: none;
}
.blog blockquote,
.blog q {
  quotes: none;
}
.blog blockquote:before,
.blog blockquote:after,
.blog q:before,
.blog q:after {
  content: "";
  content: none;
}
.blog table {
  border-spacing: 0;
  border-collapse: collapse;
}
.blog {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
.blog hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.blog a {
  background-color: transparent;
}
.blog img {
  border-style: none;
}
.blog button,
.blog input,
.blog optgroup,
.blog select,
.blog textarea {
  font: inherit;
  line-height: inherit;
  margin: 0;
}
.blog button,
.blog input {
  overflow: visible;
}
.blog button,
.blog select {
  text-transform: none;
}
.blog button,
.blog [type="button"],
.blog [type="reset"],
.blog [type="submit"] {
  -webkit-appearance: button;
}
.blog button::-moz-focus-inner,
.blog [type="button"]::-moz-focus-inner,
.blog [type="reset"]::-moz-focus-inner,
.blog [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
.blog button:-moz-focusring,
.blog [type="button"]:-moz-focusring,
.blog [type="reset"]:-moz-focusring,
.blog [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
.blog legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}
.blog progress {
  vertical-align: baseline;
}
.blog textarea {
  overflow: auto;
}
.blog [type="checkbox"],
.blog [type="radio"] {
  padding: 0;
}
.blog [type="number"]::-webkit-inner-spin-button,
.blog [type="number"]::-webkit-outer-spin-button {
  height: auto;
}
.blog [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
.blog [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.blog ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.blog summary {
  display: list-item;
}
.blog template {
  display: none;
}
.blog [hidden] {
  display: none !important;
}
.blog {
  --fg: 25, 25, 39;
  --bg: 255, 255, 255;
}
.blog {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 17.5px;
}
@media (min-width: 960px) {
  .blog {
    font-size: 20px;
  }
}
.blog *,
.blog *:before,
.blog *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.blog ::-webkit-input-placeholder {
  color: rgba(25, 25, 39, 0.4);
  color: rgba(var(--fg), 0.4);
}
.blog :-ms-input-placeholder {
  color: rgba(25, 25, 39, 0.4);
  color: rgba(var(--fg), 0.4);
}
.blog ::-ms-input-placeholder {
  color: rgba(25, 25, 39, 0.4);
  color: rgba(var(--fg), 0.4);
}
.blog ::placeholder {
  color: rgba(25, 25, 39, 0.4);
  color: rgba(var(--fg), 0.4);
}
.blog {
  font-family: Charter, Georgia, serif;
  line-height: 1.4;
  letter-spacing: 0;
  background-color: #fff;
  background-color: rgba(var(--bg), 1);
  color: #191927;
  color: rgba(var(--fg), 1);
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  word-wrap: break-word;
  overflow-x: hidden;
}
.blog .clearfix:after {
  display: block;
  clear: both;
  content: "";
}
.blog h1,
.blog h2,
.blog h3,
.blog h4,
.blog h5,
.blog h6 {
  font-weight: normal;
}
.blog h1 {
  font-size: 2rem;
  line-height: 1.1;
  letter-spacing: -0.0175em;
}
@media (min-width: 960px) {
  .blog h1 {
    font-size: 2.25rem;
    line-height: 1.05;
  }
}
@media (min-width: 1280px) {
  .blog h1 {
    font-size: 2.5rem;
  }
}
.blog .content h1 {
  margin-top: 2.8rem;
  margin-bottom: 0.7rem;
}
.blog h2 {
  font-size: 1.3333333333rem;
  line-height: 1.25;
  letter-spacing: -0.0075em;
}
@media (min-width: 960px) {
  .blog h2 {
    font-size: 1.5rem;
  }
}
.blog .content h2 {
  margin-top: 2.8rem;
  margin-bottom: 0.7rem;
}
.blog h3 {
  font-size: 1.1666666667rem;
  line-height: 1.3;
  letter-spacing: -0.005em;
}
@media (min-width: 960px) {
  .blog h3 {
    font-size: 1.25rem;
  }
}
.blog .content h3 {
  margin-top: 2.1rem;
  margin-bottom: 0.7rem;
}
.blog h4,
.blog h5,
.blog h6 {
  font-size: 1rem;
}
.blog .content h4,
.blog .content h5,
.blog .content h6 {
  margin-top: 2.1rem;
  margin-bottom: 0.7rem;
}
.blog .content h4 {
  font-weight: bold;
}
.blog .content h5 {
  font-variant-caps: small-caps;
  text-transform: lowercase;
}
.blog .content h6 {
  font-style: italic;
}
.blog .content h1:first-child,
.blog .content h2:first-child,
.blog .content h3:first-child,
.blog .content h4:first-child,
.blog .content h5:first-child,
.blog .content h6:first-child {
  margin-top: 0;
}
.blog hr {
  opacity: 0.1;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
}
.blog .content hr {
  margin: 2.1rem 0;
}
.blog .content p,
.blog .content ol,
.blog .content ul,
.blog .content blockquote {
  font-size: 1em;
  margin-bottom: 1.4rem;
}
.blog .content ol,
.blog .content ul {
  padding-left: 1.25em;
}
@media (min-width: 1280px) {
  .blog .content ol,
  .blog .content ul {
    padding-left: 0;
  }
}
.blog .content ol {
  list-style-type: decimal;
}
.blog .content ul {
  list-style-type: disc;
}
.blog .content li {
  margin-bottom: 0.4666666667rem;
}
.blog .content li ol,
.blog .content li ul {
  padding-left: 1.25em;
  margin-top: 0.4666666667rem;
  margin-bottom: 0;
}
.blog .content li ol {
  list-style-type: lower-alpha;
}
.blog .content li li ol {
  list-style-type: lower-roman;
}
.blog .content li ul {
  list-style-type: circle;
}
.blog .content li li ul {
  list-style-type: square;
}
.blog blockquote.pullquote {
  padding-left: 1.25em;
  position: relative;
  font-style: italic;
}
@media (min-width: 1280px) {
  .blog blockquote.pullquote {
    padding-left: 0;
  }
}
.blog blockquote.pullquote em {
  font-style: normal;
}
.blog blockquote.pullquote p {
  margin-bottom: 0;
}
.blog blockquote.pullquote:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: rgba(25, 25, 39, 0.2);
  background-color: rgba(var(--fg), 0.2);
  left: 0;
}
@media (min-width: 1280px) {
  .blog blockquote.pullquote:after {
    left: -1em;
  }
}
.blog strong {
  font-weight: bold;
}
.blog em {
  font-style: italic;
}
.blog small {
  font-size: 0.8333333333em;
}
.blog mark {
  background-color: #191927;
  background-color: rgba(var(--fg), 1);
  color: #fff;
  color: rgba(var(--bg), 1);
}
.blog sub,
.blog sup {
  font-family: Helvetica, sans-serif;
  font-size: 0.75rem;
  font-weight: unset;
  letter-spacing: unset;
  font-size: 0.6666666667em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
.blog sub a:not(.no-underline),
.blog sup a:not(.no-underline) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  -webkit-text-decoration-color: rgba(25, 25, 39, 0.2) !important;
  text-decoration-color: rgba(25, 25, 39, 0.2) !important;
  -webkit-text-decoration-color: rgba(var(--fg), 0.2) !important;
  text-decoration-color: rgba(var(--fg), 0.2) !important;
}
.blog sub {
  bottom: -0.25em;
}
.blog sup {
  top: -0.5em;
}
.blog pre,
.blog code,
.blog kbd,
.blog samp {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
    monospace;
  border-radius: 4px;
}
.blog code,
.blog kbd,
.blog samp {
  font-size: 0.8333333333em;
}
.blog pre,
.blog code,
.blog kbd {
  background-color: rgba(25, 25, 39, 0.03);
  background-color: rgba(var(--fg), 0.03);
}
.blog code,
.blog kbd {
  padding: 0 0.2em;
}
.blog pre {
  font-size: 0.75em;
  padding: 1em;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  overflow: auto;
}
.blog pre code {
  font-size: 1em;
  background-color: transparent !important;
  color: inherit;
  padding: 0;
}
.blog .content section:not(.footnotes) {
  margin-bottom: 1.4rem;
}
.blog a {
  text-decoration: none;
  color: inherit;
}
.blog .content a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  -webkit-text-decoration-color: rgba(25, 25, 39, 0.4);
  text-decoration-color: rgba(25, 25, 39, 0.4);
  -webkit-text-decoration-color: rgba(var(--fg), 0.4);
  text-decoration-color: rgba(var(--fg), 0.4);
}
.blog .content a:hover {
  opacity: 0.6;
}
.blog img,
.blog video,
.blog iframe {
  max-width: 100%;
  display: block;
}
.blog .content img,
.blog .content video,
.blog .content iframe {
  margin-bottom: 1.4rem;
}
.blog figure {
  font-family: Helvetica, sans-serif;
  margin-bottom: 1.4rem;
}
.blog figcaption,
.blog .caption,
.blog .caption-unspaced {
  font-family: "ApercuRegularPro", system-ui, Helvetica, sans-serif;
  font-size: 0.75rem;
  font-weight: unset;
  letter-spacing: unset;
}
.blog figcaption a:not(.no-underline),
.blog .caption a:not(.no-underline),
.blog .caption-unspaced a:not(.no-underline) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  -webkit-text-decoration-color: rgba(25, 25, 39, 0.2) !important;
  text-decoration-color: rgba(25, 25, 39, 0.2) !important;
  -webkit-text-decoration-color: rgba(var(--fg), 0.2) !important;
  text-decoration-color: rgba(var(--fg), 0.2) !important;
}
.blog .small-caption {
  font-family: Helvetica, sans-serif;
  font-size: 0.6666666667rem;
  font-weight: unset;
  letter-spacing: unset;
  padding-top: 0.35rem;
}
.blog .small-caption a:not(.no-underline) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  -webkit-text-decoration-color: rgba(25, 25, 39, 0.2) !important;
  text-decoration-color: rgba(25, 25, 39, 0.2) !important;
  -webkit-text-decoration-color: rgba(var(--fg), 0.2) !important;
  text-decoration-color: rgba(var(--fg), 0.2) !important;
}
.blog figcaption,
.blog .caption,
.blog .small-caption,
.blog .caption-unspaced {
  color: rgba(25, 25, 39, 0.5);
  color: rgba(var(--fg), 0.5);
}
.blog figcaption,
.blog .caption,
.blog .small-caption {
  padding-top: 0.4666666667rem;
  margin-top: -1.4rem;
}
.blog .caption,
.blog .small-caption {
  display: block;
  margin-bottom: 1.4rem;
}
.blog p > .caption:only-of-type {
  margin-top: -1.4rem;
}
.blog img + br + .caption {
  margin-top: -2.8rem !important;
}
.blog .caption-unspaced {
  display: block;
}
@media (min-width: 720px) {
  .blog .footnote-aside,
  .blog .aside,
  .blog .aside-left,
  .blog .aside-right {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    width: 33.3333333333%;
    position: absolute;
    right: 0;
  }
}
@media (min-width: 960px) {
  .blog .footnote-aside,
  .blog .aside,
  .blog .aside-left,
  .blog .aside-right {
    width: 50%;
  }
}
@media (min-width: 720px) {
  .blog .aside,
  .blog .aside-left {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@media (min-width: 1280px) {
  .blog .aside,
  .blog .aside-left {
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.blog .footnote-aside {
  font-family: "ApercuRegularPro", system-ui, Helvetica, sans-serif;
  font-size: 0.75rem;
  font-weight: unset;
  letter-spacing: unset;
  color: rgba(var(--fg), 0.5);
  display: none;
  margin-top: calc(-1.4em - 2px);
}
.blog .footnote-aside a:not(.no-underline) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  -webkit-text-decoration-color: rgba(25, 25, 39, 0.2) !important;
  text-decoration-color: rgba(25, 25, 39, 0.2) !important;
  -webkit-text-decoration-color: rgba(var(--fg), 0.2) !important;
  text-decoration-color: rgba(var(--fg), 0.2) !important;
}
@media (min-width: 720px) {
  .blog .footnote-aside {
    display: block;
  }
}
@media (min-width: 720px) {
  .blog .footnote-aside,
  .blog .aside-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
.blog table {
  font-family: Helvetica, sans-serif;
  font-variant-numeric: tabular-nums;
  border-collapse: separate;
  border-spacing: 2px;
  overflow-x: auto;
  margin-top: 2.1rem;
  margin-bottom: 2.1rem;
  width: 100%;
  display: inherit;
}
.blog th {
  font-family: Helvetica, sans-serif;
  font-size: 0.6666666667rem;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  vertical-align: bottom;
}
.blog th,
.blog td {
  padding: 0.35rem;
}
.blog td {
  font-family: Helvetica, sans-serif;
  font-size: 0.8333333333rem;
  font-weight: unset;
  letter-spacing: unset;
}
.blog tr:nth-child(odd) td {
  background-color: rgba(25, 25, 39, 0.05);
  background-color: rgba(var(--fg), 0.05);
}
.blog .content-container {
  max-width: 1470px;
  width: 93.3333333333%;
  margin-left: auto;
  margin-right: auto;
}
.blog .content-container h1,
.blog .content-container h2,
.blog .content-container h3,
.blog .content-container h4 {
  font-family: Georgia, serif !important;
}
@media (min-width: 720px) {
  .blog .content-container {
    width: 93.3333333333%;
  }
}
.blog .bg-shadow {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(5, 5, 5, 0.02)),
    color-stop(5%, rgba(255, 255, 255, 0)),
    color-stop(95%, rgba(255, 255, 255, 0)),
    to(rgba(5, 5, 5, 0.02))
  );
  background-image: linear-gradient(
    180deg,
    rgba(5, 5, 5, 0.02) 0%,
    rgba(255, 255, 255, 0) 5%,
    rgba(255, 255, 255, 0) 95%,
    rgba(5, 5, 5, 0.02) 100%
  );
}
.blog .full {
  position: relative;
  width: 100vw;
  left: 0;
  -webkit-transform: translateX(-3.3333333333vw);
  transform: translateX(-3.3333333333vw);
  margin-top: 4.2rem;
  margin-bottom: 4.2rem;
}
@media (min-width: 720px) {
  .blog .full {
    -webkit-transform: translateX(-3.3333333333vw);
    transform: translateX(-3.3333333333vw);
  }
}
@media (min-width: 1280px) {
  .blog .full {
    left: 50%;
    -webkit-transform: translateX(-50vw);
    transform: translateX(-50vw);
  }
}
.blog .full img {
  width: 100%;
}
.blog .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -1.6666666667vw;
  margin-right: -1.6666666667vw;
}
@media (min-width: 720px) {
  .blog .row {
    margin-left: -0.7rem;
    margin-right: -0.7rem;
  }
}
.blog .no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.blog .content {
  position: relative;
  width: 100%;
  padding-right: 1.6666666667vw;
  padding-left: 1.6666666667vw;
}
@media (min-width: 720px) {
  .blog .content {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
  }
}
.blog .content {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.blog .content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 720px) {
  .blog .content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media (min-width: 960px) {
  .blog .content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
}
@media (min-width: 1280px) {
  .blog .content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .blog .content {
    margin-left: 25%;
  }
}
.blog legend,
.blog label {
  display: inline-block;
}
.blog select {
  word-wrap: normal;
  background-color: transparent;
  background-image: none;
}
.blog .footnote-ref,
.blog .reference-ref {
  margin-left: 0.05em;
  margin-right: 0.05em;
}
.blog .reference-ref.grouped {
  margin-left: -0.05em;
}
.blog .reference-ref.grouped:before {
  content: ",";
}
.blog .footnote-ref a,
.blog .reference-ref a {
  text-decoration: none !important;
}
.blog .footnote-ref a:target,
.blog .footnote-item:target,
.blog .reference-ref a:target,
.blog .reference:target {
  background-color: rgba(25, 25, 39, 0.05);
  background-color: rgba(var(--fg), 0.05);
}
.blog .reference p {
  display: inline;
}
.blog .footnotes-sep {
  display: none;
}
.blog .footnotes p {
  margin-bottom: 0;
}
.blog .footnotes-list {
  margin-bottom: 0 !important;
}

@media (max-width: 1279px) {
  .blog .xl-only {
    display: none;
  }
}

.blog-content-width {
  max-width: 1470px;
  width: 93.3333333333%;
  margin-left: auto;
  margin-right: auto;

  .w-0 a {
    visibility: hidden;
  }
}
@media (min-width: 720px) {
  .blog-content-width {
    width: 93.3333333333%;
  }
}

/* Fix "Drug" cutoff in modal charts. */
svg.marks {
  overflow: visible !important;
}

.blog .megamap-full {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
@media (min-width: 480px) {
  .blog .megamap-full {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem;
  }
}

/* TODO(charlie): This exists to support Component 2 of the multi-disease post, but should be more
   targeted. */
.plotly * {
  cursor: unset !important;
  pointer-events: none !important;
}
