@font-face {
  font-family: "ApercuBoldPro";
  src: url("../fonts/apercu_bold_pro.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "ApercuMediumPro";
  src: url("../fonts/apercu_medium_pro.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ApercuMonoPro";
  src: url("../fonts/apercu_mono_pro.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ApercuRegularItalicPro";
  src: url("../fonts/apercu_regular_italic_pro.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ApercuLightPro";
  src: url("../fonts/apercu_light_pro.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "ApercuRegularPro";
  src: url("../fonts/apercu_regular_pro.otf");
  font-weight: normal;
  font-style: normal;
}
