#schematic:not(.run-animation):not(.baseState) {
  opacity: 0;
  transition: 200ms opacity ease-in;
}

#schematic {
  opacity: 1;
  transition: 200ms opacity ease-in;
}

#schematic:not(.run-animation) .unhighlight {
  transform: skewY(0);
  opacity: 1;
}

#schematic:not(.run-animation) .highlightColumn {
  transform: skewY(0);
  opacity: 1;
}

#schematic:not(.run-animation) .annotation {
  opacity: 0;
}

#schematic:not(.run-animation) .highlightPane {
  opacity: 0;
}

#schematic .hoverCursor {
  transform: translateX(-30%) scale(1.5, 1.2) skewY(18deg);
  opacity: 1;
}

#schematic .highlightPane {
  transform: translateX(20%) skewY(18deg);
}

@keyframes schematicUnhighlighted {
  0% {
    transform: skewY(0);
    opacity: 1;
  }
  25% {
    transform: skewY(18deg);
    opacity: 1;
  }
  75% {
    transform: translateX(20%) skewY(18deg);
    opacity: 0.25;
  }
  100% {
    transform: translateX(20%) skewY(18deg);
    opacity: 0.25;
  }
}

@keyframes schematicHighlighted {
  0% {
    transform: skewY(0);
    opacity: 1;
  }
  25% {
    transform: skewY(18deg);
    opacity: 1;
  }
  75% {
    transform: translateX(-30%) scale(1.5, 1.2) skewY(18deg);
    opacity: 1;
  }
  100% {
    transform: translateX(-30%) scale(1.5, 1.2) skewY(18deg);
    opacity: 1;
  }
}

@keyframes schematicHighlightPane {
  0% {
    opacity: 0;
  }
  25% {
    transform: skewY(18deg);
    opacity: 0;
  }
  75% {
    transform: translateX(20%) skewY(18deg);
    opacity: 1;
  }
  100% {
    transform: translateX(20%) skewY(18deg);
    opacity: 1;
  }
}

@keyframes schematicAnnotation {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* First animation. */
#schematic.run-animation .unhighlight {
  animation-name: schematicUnhighlighted;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

#schematic.run-animation .highlightColumn {
  animation-name: schematicHighlighted;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

#schematic.run-animation .highlightPane {
  animation-name: schematicHighlightPane;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

#schematic.run-animation .annotation {
  animation-name: schematicAnnotation;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}
