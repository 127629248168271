#miniTable.baseState .unhighlight {
  transform: skewY(18deg);
  opacity: 1;
}

#miniTable.baseState .highlightColumn {
  transform: skewY(18deg);
  opacity: 1;
}

#miniTable.baseState .highlightColumnExit {
  transform: skewY(18deg);
  opacity: 1;
}

#miniTable .hoverCursor {
  transform: translateX(-10%) scale(1.5, 1.2) skewY(18deg);
  opacity: 1;
}

#miniTable.baseState .annotation {
  opacity: 0;
}

@keyframes middleToBack {
  0% {
    transform: translateX(0) skewY(18deg);
    opacity: 1;
  }
  100% {
    transform: translateX(20%) skewY(18deg);
    opacity: 0.1;
  }
}

@keyframes middleToFront {
  0% {
    transform: translateX(0) skewY(18deg);
    opacity: 1;
  }
  100% {
    transform: translateX(-10%) scale(1.5, 1.2) skewY(18deg);
    opacity: 1;
  }
}

@keyframes backToMiddleToBack {
  0% {
    transform: translateX(20%) skewY(18deg);
    opacity: 0.1;
  }
  50% {
    transform: translateX(0) skewY(18deg);
    opacity: 1;
  }
  100% {
    transform: translateX(20%) skewY(18deg);
    opacity: 0.1;
  }
}

@keyframes backToMiddleToFront {
  0% {
    transform: translateX(20%) skewY(18deg);
    opacity: 0.1;
  }
  50% {
    transform: translateX(0) skewY(18deg);
    opacity: 1;
  }
  100% {
    transform: translateX(-10%) scale(1.5, 1.2) skewY(18deg);
    opacity: 1;
  }
}

@keyframes frontToMiddleToBack {
  0% {
    transform: translateX(-10%) scale(1.5, 1.2) skewY(18deg);
    opacity: 1;
  }
  50% {
    transform: translateX(0) skewY(18deg);
    opacity: 1;
  }
  100% {
    transform: translateX(20%) skewY(18deg);
    opacity: 0.1;
  }
}

@keyframes annotation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* First animation. */
#miniTable:not(.baseState):not(.reverse) .unhighlight {
  transform: skewY(18deg);
  opacity: 1;
}

#miniTable:not(.baseState):not(.reverse) .unhighlight.run-animation {
  animation-name: middleToBack;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

#miniTable:not(.baseState):not(.reverse) .highlightColumn {
  transform: skewY(18deg);
  opacity: 1;
}

#miniTable:not(.baseState):not(.reverse) .highlightColumn.run-animation {
  animation-name: middleToFront;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

#miniTable:not(.baseState):not(.reverse) .highlightColumnExit {
  transform: skewY(18deg);
  opacity: 1;
}

#miniTable:not(.baseState):not(.reverse) .highlightColumnExit.run-animation {
  animation-name: middleToBack;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

#miniTable:not(.baseState):not(.reverse) .annotation {
  animation-name: annotation;
  animation-duration: 200ms;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

#miniTable:not(.baseState).reverse .hoverCursor {
  transform: translateX(-10%) scale(1.5, 1.2) skewY(18deg);
  opacity: 1;
}

/* Subsequent animations. */
#miniTable:not(.baseState).reverse .unhighlight {
  transform: translateX(20%) skewY(18deg);
  opacity: 0.1;
}

#miniTable:not(.baseState).reverse .unhighlight.run-animation {
  animation-name: backToMiddleToBack;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

#miniTable:not(.baseState).reverse .highlightColumn {
  transform: translateX(20%) skewY(18deg);
  opacity: 0.1;
}

#miniTable:not(.baseState).reverse .highlightColumn.run-animation {
  animation-name: backToMiddleToFront;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

#miniTable:not(.baseState).reverse .highlightColumnExit {
  transform: translateX(-10%) scale(1.5, 1.2) skewY(18deg);
  opacity: 1;
}

#miniTable:not(.baseState).reverse .highlightColumnExit.run-animation {
  animation-name: frontToMiddleToBack;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
